import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import pt_BR from './locales/pt-BR'

i18n.use(initReactI18next).init({
  resources: {
    'pt-BR': pt_BR,
  },
  lng: 'pt-BR',
  debug: true,
});

export default i18n;
