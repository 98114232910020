import React from 'react';

const states = [
  {
    "idUf": 1,
    "sigla": "AC",
    "estado": "Acre",
    "codigo": 12,
    "idPais": 23
  },
  {
    "idUf": 2,
    "sigla": "AL",
    "estado": "Alagoas",
    "codigo": 27,
    "idPais": 23
  },
  {
    "idUf": 3,
    "sigla": "AM",
    "estado": "Amazonas",
    "codigo": 13,
    "idPais": 23
  },
  {
    "idUf": 4,
    "sigla": "AP",
    "estado": "Amapá",
    "codigo": 16,
    "idPais": 23
  },
  {
    "idUf": 5,
    "sigla": "BA",
    "estado": "Bahia",
    "codigo": 29,
    "idPais": 23
  },
  {
    "idUf": 6,
    "sigla": "CE",
    "estado": "Ceará",
    "codigo": 23,
    "idPais": 23
  },
  {
    "idUf": 7,
    "sigla": "DF",
    "estado": "Distrito Federal",
    "codigo": 53,
    "idPais": 23
  },
  {
    "idUf": 8,
    "sigla": "ES",
    "estado": "Espírito Santo",
    "codigo": 32,
    "idPais": 23
  },
  {
    "idUf": 9,
    "sigla": "GO",
    "estado": "Goiás",
    "codigo": 52,
    "idPais": 23
  },
  {
    "idUf": 10,
    "sigla": "MA",
    "estado": "Maranhão",
    "codigo": 21,
    "idPais": 23
  },
  {
    "idUf": 11,
    "sigla": "MG",
    "estado": "Minas Gerais",
    "codigo": 31,
    "idPais": 23
  },
  {
    "idUf": 12,
    "sigla": "MS",
    "estado": "Mato Grosso do Sul",
    "codigo": 50,
    "idPais": 23
  },
  {
    "idUf": 13,
    "sigla": "MT",
    "estado": "Mato Grosso",
    "codigo": 51,
    "idPais": 23
  },
  {
    "idUf": 14,
    "sigla": "PA",
    "estado": "Pará",
    "codigo": 15,
    "idPais": 23
  },
  {
    "idUf": 15,
    "sigla": "PB",
    "estado": "Paraíba",
    "codigo": 25,
    "idPais": 23
  },
  {
    "idUf": 16,
    "sigla": "PE",
    "estado": "Pernambuco",
    "codigo": 26,
    "idPais": 23
  },
  {
    "idUf": 17,
    "sigla": "PI",
    "estado": "Piauí",
    "codigo": 22,
    "idPais": 23
  },
  {
    "idUf": 18,
    "sigla": "PR",
    "estado": "Paraná",
    "codigo": 41,
    "idPais": 23
  },
  {
    "idUf": 19,
    "sigla": "RJ",
    "estado": "Rio de Janeiro",
    "codigo": 33,
    "idPais": 23
  },
  {
    "idUf": 20,
    "sigla": "RN",
    "estado": "Rio Grande do Norte",
    "codigo": 24,
    "idPais": 23
  },
  {
    "idUf": 21,
    "sigla": "RO",
    "estado": "Rondônia",
    "codigo": 11,
    "idPais": 23
  },
  {
    "idUf": 22,
    "sigla": "RR",
    "estado": "Roraima",
    "codigo": 14,
    "idPais": 23
  },
  {
    "idUf": 23,
    "sigla": "RS",
    "estado": "Rio Grande do Sul",
    "codigo": 43,
    "idPais": 23
  },
  {
    "idUf": 24,
    "sigla": "SC",
    "estado": "Santa Catarina",
    "codigo": 42,
    "idPais": 23
  },
  {
    "idUf": 25,
    "sigla": "SE",
    "estado": "Sergipe",
    "codigo": 28,
    "idPais": 23
  },
  {
    "idUf": 26,
    "sigla": "SP",
    "estado": "São Paulo",
    "codigo": 35,
    "idPais": 23
  },
  {
    "idUf": 27,
    "sigla": "TO",
    "estado": "Tocantins",
    "codigo": 17,
    "idPais": 23
  }
]

export default states;