import React, { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const Plano = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { nome_do_plano } = useParams();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryString = queryParams.toString() ? '&'+queryParams.toString() : '';

    console.log(`redirect to /?produto=hospedagem.contratar?plano=${nome_do_plano}`); 
    navigate(`/?produto=hospedagem.contratar?plano=${nome_do_plano}${queryString}`, { replace: true });
  }, [navigate, location, nome_do_plano]);

  return <div></div>;
}

export default Plano;