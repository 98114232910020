import React, { useEffect } from 'react';
import  { useLocation, useParams } from 'react-router-dom'

const Credito = () => {

  const location = useLocation();
  const { tipo } = useParams();

  useEffect(() => {
    // Obtém a query string da localização atual
    const queryParams = new URLSearchParams(location.search);
    const queryString = queryParams.toString() ? '?'+queryParams.toString() : '';

    // Monta o novo URL de destino com a mesma query string
    const novoURL = `https://assine2.hostnet.com.br/credito/${tipo}${queryString}`;
    // Redireciona para o novo URL
    //window.alert(novoURL);
    window.location.href = novoURL;
  }, [location, tipo]);

  return <div></div>
}

export default Credito;