import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from "yup";
import countryList from './country-list';


interface FormInputProps {
    formSubmit: (values: any) => void;
    f: any;
    setIsShown?:React.Dispatch<React.SetStateAction<boolean>>;
    formikParent?:any;
}

const FormInt: React.FC<FormInputProps> = ({formSubmit, f,setIsShown,formikParent}) => {
  const countries = countryList;
  const { t } = useTranslation();
    const [mask, setMask] = useState("999999999999999");
    
    const formik = useFormik({
        validateOnMount: true,
        enableReinitialize: true,
        initialValues: {
          name: '',
          birthdate: '',
          account_type: '',
          email2: '',
          ddi: '',
          mobile: '',
          country: '',
          postalcode: '',
          address: '',
          city: '',
        },
        validationSchema: Yup.object().shape({
          name: Yup.string()
            .min(10, 'O nome precisa ter ao menos 10 caracteres')
            .required(t('validations:requiredField'))
            .test('countWords', 'Preencha o nome completo', val => {
              const value = val || '';
              const match = value.match(/\S+/g) || []
              return match.length > 1 
            }),
          birthdate: Yup.string()
            .required(t('validations:requiredField')),
          account_type: Yup.string()
            .required(t('validations:requiredField')),
          email2: Yup.string()
            .required(t('validations:requiredField'))
            .test('email2', 'O email alternativo não pode ser o mesmo do email principal', (val) => {
              var formData = new FormData(f.current);
              const email1 = Object.fromEntries(formData).email1;
              return email1 !== val 
            })
            .email(t('validations:invalidEmail')),
          ddi: Yup.string()
            .required(t('validations:requiredField')),
          mobile: Yup.string()
            .required(t('validations:requiredField')),
          country: Yup.string()
            .required(t('validations:requiredField')),
          postalcode: Yup.string()
            .required(t('validations:requiredField')),
          address: Yup.string()
            .required(t('validations:requiredField')),
          city: Yup.string()
            .required(t('validations:requiredField')),
          
        }),
        onSubmit: (values: any) => {
          formSubmit(values);
        },
      });
  useEffect(() => {
        console.log(formikParent.values)
        if (formikParent && formikParent.values) {
          if (formikParent.values.name) {
            formik.setFieldValue('name', formikParent.values.name);
          }
          //if (formikParent.values.country) {
            formik.setFieldValue('country', formikParent.values.country);
          //}
          if (formikParent.values.birthdate) {
            formik.setFieldValue('birthdate', formikParent.values.birthdate);
          }
          if (formikParent.values.email2) {
            formik.setFieldValue('email2', formikParent.values.email2);
          }
        }
      },[])

      const handleShowHide = (e: any, setFieldValue: any) => {
        if(formikParent.values && formikParent.values.country){formikParent.setFieldValue('country',e.target.value)}
        if(setIsShown){e.target.value==='Brazil'?setIsShown(true):setIsShown(false)};
        setFieldValue('country', e.target.value);
      }
      
    return<>
      <Card className="m-3 rounded-0">
        <Card.Body className="custom-bg-card">
          <Form onSubmit={formik.handleSubmit} id="formInt">
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="name" className="col-form-label-sm">Nome</Form.Label>
                  <Form.Control
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    isInvalid={!!formik.touched.name && !!formik.errors.name}
                    disabled={formik.isSubmitting}
                    className="form-control form-control-sm rounded-0" />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                
                
                <Row className="mb-3">
                
                </Row>
                

                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3 mb-md-0">
                    <Form.Label htmlFor="birthdate" className="col-form-label-sm">Nascimento</Form.Label>
                    <Form.Control
                      id="birthdate"
                      name="birthdate"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.birthdate}
                      disabled={formik.isSubmitting}
                      isInvalid={!!formik.touched.birthdate && !!formik.errors.birthdate}
                      className="form-control form-control-sm rounded-0" />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.birthdate}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} className="mb-md-0">
                    <Form.Label htmlFor="account_type" className="col-form-label-sm">Tipo de conta</Form.Label>
                    <Form.Select
                      id="account_type"
                      name="account_type"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.account_type}
                      disabled={formik.isSubmitting}
                      isInvalid={!!formik.touched.account_type && !!formik.errors.account_type}
                      className="form-select form-select-sm rounded-0" >
                          <option value="">Selecione o tipo de conta</option>
                          <option value="DONO" label="Dono">Sou dono desta conta</option>
                          <option value="DESENVOLVEDOR" label="Desenvolvedor">Sou desenvolvedor desta conta</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.account_type}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="email2" className="col-form-label-sm">Email alternativo</Form.Label>
                  <Form.Control
                    id="email2"
                    name="email2"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email2}
                    disabled={formik.isSubmitting}
                    isInvalid={!!formik.touched.email2 && !!formik.errors.email2}
                    className="form-control form-control-sm rounded-0" />
                  <Form.Control.Feedback type="invalid">
                      {formik.errors.email2}
                    </Form.Control.Feedback>
                </Form.Group>

                <Row>
                  <Form.Group as={Col} sm={3} className="mb-3 mb-md-0">
                    <Form.Label htmlFor="ddi" className="col-form-label-sm">DDI</Form.Label>
                    <Form.Control
                      id="ddi"
                      name="ddi"
                      type="string"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.ddi}
                      disabled={formik.isSubmitting}
                      isInvalid={!!formik.touched.ddi && !!formik.errors.ddi}
                      className="form-control form-control-sm rounded-0" />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.ddi}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3 mb-md-0">
                    <Form.Label htmlFor="telephone" className="col-form-label-sm">Telefone</Form.Label>
                    <Form.Control
                      as={InputMask}
                      id="mobile"
                      name="mobile"
                      type="string"
                      mask={mask}
                      onChange={formik.handleChange}
                      disabled={formik.isSubmitting}
                      
                      value={formik.values.mobile}
                      isInvalid={!!formik.touched.mobile && !!formik.errors.mobile}
                      className="form-control form-control-sm rounded-0" />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.mobile}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Col>

              <Col>
                
                <Form.Group className="mb-4">
                  <Form.Label htmlFor="country" className="col-form-label-sm">País</Form.Label>
                  <Form.Select
                    id="country"
                    name="country"
                    onChange={(e)=>(handleShowHide(e, formik.setFieldValue))}
                    onBlur={formik.handleBlur}
                    value={formik.values.country}
                    disabled={formik.isSubmitting}
                    isInvalid={!!formik.touched.country && !!formik.errors.country}
                    className="form-select form-select-sm rounded-0" >
                      {countries.map(country => (
                        <option key={country.idPais} value={country.paisEn}>
                          {country.pais}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.country}
                  </Form.Control.Feedback>
                </Form.Group>

                <Row className="mb-3">
                  <Form.Group className="col-md mt-2 mb-md-0">
                    <Form.Label htmlFor="postalcode" className="col-form-label-sm">Código postal</Form.Label>
                    <Form.Control
                      as={InputMask}
                      id="postalcode"
                      name="postalcode"
                      type="string"
                      mask=""
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      
                      value={formik.values.postalcode}
                      isInvalid={!!formik.touched.postalcode && !!formik.errors.postalcode}
                      className="form-control form-control-sm rounded-0" />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.postalcode}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="col-md mt-2 mb-md-0">
                    <Form.Label htmlFor="address" className="col-form-label-sm">Endereço</Form.Label>
                    <Form.Control
                      id="address"
                      name="address"
                      type="string"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      value={formik.values.address}
                      isInvalid={!!formik.touched.address && !!formik.errors.address}
                      className="form-control form-control-sm rounded-0" />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="city" className="col-form-label-sm">Cidade/Estado/Localidade</Form.Label>
                  <Form.Control
                    id="city"
                    name="city"
                    type="string"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    disabled={formik.isSubmitting}
                    isInvalid={!!formik.touched.city && !!formik.errors.city}
                    className="form-control form-control-sm rounded-0" />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.city}
                  </Form.Control.Feedback>
                </Form.Group>

            </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Card className="m-3 rounded-0">
        <Card.Body className="custom-bg-card text-secondary custom-font-size-sm">
          <Card.Text className="mb-2">
            <span className="ajuda"></span><span>Insira o código postal e demais números para contato sem pontuação ou traços.</span>
          </Card.Text>
          <Card.Text className="mb-2">
            <span className="ajuda"></span><span>Utilize sempre dois endereços de e-mail, o principal e o alternativo (Gmail, Hotmail e outros). Desta forma garantirá o recebimento de todos os nossos comunicados.</span>
          </Card.Text>
          <Card.Text className="mb-2">
            <span className="ajuda"></span><span>Optando pela forma de pagamento via boleto bancário, ele será enviado aos e-mails de cadastro.</span>
          </Card.Text>
        </Card.Body>
      </Card>
      <Button
        form='formInt'
        variant="warning"
        type="submit"
        size="lg"
        className="mx-3 mb-3 nextStep" >Enviar
      </Button>
      
    </>;
        
}
export default FormInt;